var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"},[_c('h1',{staticClass:"title"},[_vm._v("Portal")])])]),_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"level-item"},[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column"},[(_vm.loading)?_c('progress',{staticClass:"progress is-info"}):_vm._e()]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button",on:{"click":_vm.loadReport}},[_vm._v("Refresh")])])])])])])]),(this.$store.getters.negotiatorEnabled)?_c('div',{staticClass:"tags has-addons"},[_c('span',{staticClass:"tag"},[_vm._v("Negotiator")]),_c('span',{staticClass:"tag is-success"},[_vm._v("Licensed")])]):_vm._e(),(_vm.errorMessage)?_c('p',{staticClass:"notification is-danger"},[_c('button',{staticClass:"delete",on:{"click":function($event){_vm.errorMessage = ''}}}),_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('hr'),_vm._l((Object.entries(_vm.report)),function(ref){
var k = ref[0];
var v = ref[1];
return _c('article',{key:k,staticClass:"message"},[_c('div',{staticClass:"message-header"},[_c('p',[_vm._v(_vm._s(k))])]),_c('div',{staticClass:"message-body"},_vm._l((v),function(itm){return _c('div',{key:itm.left,staticClass:"notification",class:{
          'is-success': itm.middle,
          'is-danger': !itm.middle,
          'is-warning': itm.middle && itm.right == 'Not Defined'
        }},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('p',{staticClass:"content"},[_vm._v(" "+_vm._s(itm.left)+" ")])]),_c('div',{staticClass:"column"},[_c('p',{staticClass:"content"},[_vm._v(" "+_vm._s(itm.right)+" ")])])])])}),0)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }