<template>
  <base-layout>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title">Portal</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <div class="columns is-vcentered">
            <div class="column">
              <progress class="progress is-info" v-if="loading" />
            </div>
            <div class="column">
              <div class="buttons">
                <button class="button" @click="loadReport">Refresh</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tags has-addons" v-if="this.$store.getters.negotiatorEnabled">
      <span class="tag">Negotiator</span>
      <span class="tag is-success">Licensed</span>
    </div>
    <p class="notification is-danger" v-if="errorMessage">
      <button class="delete" @click="errorMessage = ''"></button>
      {{ errorMessage }}
    </p>
    <hr />
    <article class="message" v-for="[k, v] in Object.entries(report)" :key="k">
      <div class="message-header">
        <p>{{ k }}</p>
      </div>
      <div class="message-body">
        <div
          v-for="itm in v"
          :key="itm.left"
          class="notification"
          :class="{
            'is-success': itm.middle,
            'is-danger': !itm.middle,
            'is-warning': itm.middle && itm.right == 'Not Defined'
          }"
        >
          <div class="columns">
            <div class="column">
              <p class="content">
                {{ itm.left }}
              </p>
            </div>
            <div class="column">
              <p class="content">
                {{ itm.right }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import api from "../Service";
import { mapActions } from "vuex";

export default {
  components: { BaseLayout },
  data() {
    return {
      report: [],
      errorMessage: "",
      loading: false
    };
  },
  methods: {
    ...mapActions(["fetchPaymentPortalGlobalConfig"]),
    async loadReport() {
      try {
        this.loading = true;

        await this.fetchPaymentPortalGlobalConfig();

        this.report = await api.getHealthReport();
      } catch (e) {
        this.errorMessage = e;
      } finally {
        this.loading = false;
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => vm.loadReport.call(vm));
  }
};
</script>
